.flex {
  display: flex;

  &.flex-1 {
    flex: 1;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.align-center {
    align-items: center;
  }
  &.align-end {
    align-items: flex-end;
  }

  &.justify-start {
    justify-content: flex-start;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-end {
    justify-content: end;
  }
  &.justify-space-between {
    justify-content: space-between;
  }

  &.direction-row {
    flex-direction: row;
  }
  &.direction-column {
    flex-direction: column;
  }

  &.width-auto {
    width: auto;
  }
  &.width-fill {
    width: 100%;
  }
}
