@import '../../assets/styles/colors';

#notification {
  .SnackbarContent-root {
    border-radius: 8px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08), 0px 8px 32px rgba(0, 0, 0, 0.12);
  }

  .SnackbarItem-variantSuccess {
    background-color: white;
    color: black;
    .MuiSvgIcon-root {
      fill: #9cc700;
    }
  }

  .SnackbarItem-variantError {
    background-color: white;
    color: black;
    .MuiSvgIcon-root {
      fill: $red;
    }
  }
}
