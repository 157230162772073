@import '~assets/styles/colors';
@import '~assets/styles/mixins';

.input-label {
  height: 24px;
  margin-bottom: 5px;

  .input-label__text {
    font-family: $montserrat;
    color: $darkBlue;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;

    &.required {
      position: relative;

      &::after {
        @include pseudo;
        content: '*';
        right: -7px;
        left: none;
        top: -5px;
        color: red;
      }
    }
  }

  .input-label__link {
    color: $blue;
    font-size: 14px;
    text-decoration: none;
  }
}
