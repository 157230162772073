@import '~assets/styles/colors';

.clients {
  padding: 0 30px;
  height: calc(100% - 73px);
  overflow-y: auto;
  position: relative;
  justify-content: space-between;

  .clients__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .clients__tableRow {
    :hover {
      background-color: '#2D2F7A';
    }
  }

  .clients__table-button {
    height: auto;
    font-weight: 600;
    padding-left: 0;
  }
  .clients__confirm-button {
    margin-top: 30px;
  }

  .clients__table-container {
    max-height: calc(100% - 73px);
    flex-direction: column;
    display: flex;
    width: 100%;
    flex: 1;
  }

  .clients__action-bar {
    width: 100%;
    min-height: 69px;
    max-height: 69px;
    margin-bottom: 30px;

    .action-bar__search-input {
      margin-right: 40px;
    }
    .action-bar__select-wrapper {
      margin-right: 20px;
    }
    .action-bar__checkbox {
      margin-bottom: 10px;
    }
    .action-bar__select {
      width: 200px;
    }
  }

  .clients__table-wrapper {
    overflow: hidden;
  }

  .clients__table {
    overflow-y: auto;
    width: 100%;
  }

  .clients__pagination {
    background-color: $white;
  }

  .record-amount-select {
    max-width: 150px;
  }

  .clientCountWrapper {
    padding-bottom: 20px;
    color: $darkBlue;
    font-size: 12px;

    &__count {
      font-weight: bold;
      margin-left: 20px;
    }

    span {
      font-weight: normal;
    }

    .clientCount__count:first-of-type {
      margin-right: 10px;
    }
  }
}
