@import 'assets/styles/colors';

.sidebar__link {
  display: flex;
  text-decoration: none;
  padding: 10px;
  box-sizing: border-box;
  color: white;
  background-color: transparent;
  border: none;
  font-size: 14px;
  border-radius: 5px;
  margin: 0 5px 5px;
  width: 100%;
  max-width: 160px;
  transition: 0.15s ease-in;
  text-align: left;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: $lightBlue;
  }
  &--active {
    background-color: $blue;
    .sidebar__icon path {
      fill: white;
    }
  }

  .link__label {
    margin-left: 10px;
  }
}

.sidebar__logout {
  @extend .sidebar__link;
  justify-content: center;
  color: white;
  margin-bottom: 10px;
  &:hover {
    background-color: transparent;
  }
  .sidebar__icon path {
    fill: $purple;
  }
}
