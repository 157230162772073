.month-selector {
  width: 100%;
  position: relative;

  .select__controller {
    min-width: 200px;
  }

  .select__controller span:first-of-type {
    margin-right: 5px;
  }

  svg:first-of-type {
    margin-right: 10px;
  }

  .select__options {
    width: 200px;
  }

  .select__option-item--current {
    background-color: #e7ebf0;
  }

  .option-item--faded {
    margin-left: 10px;
    color: lightgray;
  }
}
