@import '~assets/styles/colors';

.package-selector-modal {
  width: 100%;
  height: 100%;

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: no-repeat;
    border: none;
    font-size: 14px;
    cursor: pointer;
    width: 14px;
    height: 14px;

    path {
      fill: $semiGray;
    }
  }

  &__content {
    width: 510px;
    min-height: 285px;
    border-radius: 20px;
    padding: 40px;
    background-color: $white;
    position: relative;

    .select__controller {
      color: $darkBlue;
      margin: 10px 0 40px;
    }
  }
}
