@import '~assets/styles/_colors.scss';

.profile {
  max-width: 1920px;
  padding: 30px;

  .profile__leftside,
  .profile__rightside {
    width: 50%;
    max-width: 567px;
  }

  .profile__leftside {
    margin-right: 30px;
  }
  .profile__rightside {
    max-width: 374px;

    .rightside__entry {
      margin-bottom: 5px;
    }

    a {
      color: $darkBlue;
      text-decoration: none;
      font-weight: 300;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .profile__info {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    max-width: 428px;
    color: $darkBlue;
    white-space: pre-wrap;
    margin-top: 50px;
  }
}
