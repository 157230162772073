@import '~assets/styles/_colors.scss';

.message-feed {
  flex: 1;
  max-width: 100%;
}

.message-feed__message {
  margin-bottom: 20px;

  .message {
    &__container {
      background-color: #8484be26;
      padding: 15px;
      font-size: 14px;
      color: $darkBlue;
      margin-bottom: 5px;
    }
    &__title {
      font-weight: 600;
      margin-bottom: 5px;
      font-family: $montserrat;
    }
    &__button {
      height: auto;
      font-size: 12px;
      padding: 0;
      color: $darkBlue;
      &:hover {
        text-decoration: underline;
      }
    }
    &__content {
      font-weight: 400;

      margin-bottom: 4px;
    }
    &__details {
      font-size: 12px;
      color: $darkBlue;
      margin-bottom: 10px;

      .details__text {
        font-family: $montserrat;
        font-weight: 600;
      }
      .details__button {
        padding: 0;
        height: auto;
        margin-left: 10px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__attachment-icon {
      margin-right: 10px;
    }
    &__author {
      margin-right: 10px;
    }
    &__date {
      font-weight: 400;
      opacity: 0.5;
      font-family: $montserrat;
    }
    &__download {
      background-color: $white;
      height: 34px;
    }
  }

  .file-download {
    background-color: transparent;
  }
}
