@import '~assets/styles/_colors.scss';

.messages {
  padding: 30px 0;
  max-width: 1920px;

  .messages__container {
    flex: 1;
    margin-right: 40px;

    .button {
      min-width: 135px;
    }
  }

  .messages__title,
  .messages__textarea {
    margin-bottom: 20px;
    background-color: $white;
  }

  .messages__textarea {
    width: 100%;
    height: 126px;
    resize: none;
    border-radius: 5px;
    border: 1px solid $input_border;
    padding: 10px 15px;
    font-size: 16px;
    color: $darkBlue;

    &::placeholder {
      color: $darkGray;
    }

    &:focus {
      outline: 1px solid $input_border;
    }
  }

  .messages__feed {
    margin-right: 40px;
  }

  .messages__file-upload {
    margin-bottom: 20px;
  }

  .messages__empty-space {
    min-width: 470px;
  }

  .pagination {
    padding-bottom: 30px;
  }
}

.messages-wrapper {
  .record-amount-select {
    max-width: 180px;
  }
}
