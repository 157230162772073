@import 'assets/styles/colors';

.select {
  width: 100%;
  position: relative;
}

.select__controller-wrapper {
  width: 100%;
}

.select__controller {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  position: static;
  height: 40px;
  background: $white;
  border: 1px solid $input_border;
  border-radius: 5px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: $darkGray;
  width: 100%;
}

.controller__icon {
  width: 20px;
  transform: rotate(180deg);
  transition: 0.15s ease-in;
  path {
    fill: $purple;
  }
}
.controller__icon--rotated {
  transform: rotate(0);
}

.controller-wrapper__label {
  font-size: 12px;
  color: #6d6e71;
  line-height: 1;
}

.select__options {
  background-color: $white;
  border: 1px solid $divider;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: 1px;
  left: 0;
  transform: translateY(100%);
  list-style: none;
  margin: 0;
  padding: 5px;
  z-index: 5;

  &--with-labels {
    bottom: 20px;
  }
}

.select__option-item {
  line-height: 18px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.2s ease;
  &:hover {
    background-color: $blueGray;
  }

  &--empty {
    color: gray;
    cursor: default;
    padding: 0 10px;
    &:hover {
      background-color: transparent;
    }
  }
}

.select__option-icon {
  width: 20px;
  margin-right: 10px;
}

.options-fade-animation-enter {
  opacity: 0;
}

.options-fade-animation-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.options-fade-animation-enter-done {
  opacity: 1;
}

.options-fade-animation-exit {
  opacity: 1;
}

.options-fade-animation-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.options-fade-animation-exit-done {
  opacity: 0;
}
