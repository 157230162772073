@import '~assets/styles/_colors.scss';

.entry {
  color: $darkBlue;
  margin-bottom: 20px;

  .entry__label {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }

  .entry__value {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }

  .entry__section {
    margin: 15px 0;
  }

  .entry--with-prefix::before {
    content: '';
    margin-right: 10px;
  }
  .entry--phone::before {
    content: 'telefon:';
  }
  .entry--email::before {
    content: 'email:';
  }
}
