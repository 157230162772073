@import '~assets/styles/colors';

.invoices {
  padding: 0 30px;
  height: calc(100% - 73px);
  overflow-y: auto;

  .table-layout__table-wrapper {
    justify-content: space-between;
  }

  .invoices__file {
    background-color: transparent;
    padding: 0;
  }
  .invoices__file-anchor {
    font-size: 14px;
    color: $blackGray;

    span {
      font-weight: 400;
    }
  }
  .invoices__file-icon {
    background-color: $brand_bg;
    padding: 5px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }
  .invoices__stats {
    height: 73px;
    font-size: 12px;
    color: $darkBlue;
    font-weight: 600;
    align-items: flex-end;
    margin-bottom: 20px;

    .stats__number {
      font-weight: 400;
    }
  }
}
