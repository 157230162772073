@import '~assets/styles/colors';

.new-member-modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .new-member-modal__content {
    width: 780px;
    min-height: 768px;
    border-radius: 20px;
    padding: 40px;
    background-color: $white;

    .content__split {
      width: 50%;

      &:first-child {
        margin-right: 20px;
      }
    }

    .content__postal-code {
      width: 25%;
    }

    .content__place {
      width: 75%;
    }
  }

  .new-member-modal__input {
    margin-bottom: 20px;
  }

  .new-member-model__cancel-button {
    margin-right: 10px;
  }
}