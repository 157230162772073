.layout {
  height: 100vh;
  display: flex;
}

main {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
}
