@import '~assets/styles/colors';

.delete-package-modal {
    width: 100%;
    height: 100%;

    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        background: no-repeat;
        border: none;
        font-size: 14px;
        cursor: pointer;
        width: 14px;
        height: 14px;

        path {
            fill: $semiGray;
        }
    }

    &__content {
        width: 510px;
        min-height: 220px;
        border-radius: 20px;
        padding: 40px;
        background-color: $white;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .delete-package-modal-button {
            margin-left: 20px;
        }
    }

    .delete-package-modal-text {
        margin-top: 20px;
    }
}