@import '~assets/styles/_mixins.scss';
@import '~assets/styles/_colors.scss';

.start-layout {
  @include absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url(~assets/images/auth-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__topbar {
    @include fixed;
    width: 100%;
    height: 75px;
    padding: 0 36px;

    background-color: $white;

    .topbar__container {
      width: 100%;

      .topbar__logo {
        width: 171px;
        height: 37px;
      }
    }
  }

  &__content {
    width: 670px;
    height: 715px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $white;

    .content__container {
      width: 370px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}
