@import '~assets/styles/colors';

.table-layout {
  padding: 0 30px;
  height: calc(100% - 80px);
  overflow-y: auto;
  position: relative;
  justify-content: space-between;

  .table-layout__table-container {
    max-height: 100%;
    flex-direction: column;
    display: flex;
    width: 100%;

    &.with-after {
      max-height: calc(100% - 80px);
    }
    &.with-before {
      max-height: calc(100% - 120px);
    }
    &.with-after.with-before {
      max-height: calc(100% - 180px);
    }
  }

  .table-layout__table-wrapper {
    overflow: hidden;
    height: 100%;
  }

  .table-layout__table {
    overflow-y: auto;
    width: 100%;
  }

  .table-layout__pagination {
    background-color: $white;
    max-height: 50px;
    margin-bottom: 4px;
  }
}
