@import '~assets/styles/_colors.scss';

.file-download {
  background-color: $input_bg;
  padding: 0 10px;
  color: $darkBlue;
  margin-bottom: 5px;
  width: 100%;
  height: 24px;

  &__button {
    background: none;
    border: none;
    text-decoration: none;
    color: inherit;
    font-size: 12px;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    .file-download__file-name {
      font-weight: 600;
    }
    .file-download__file-size {
      font-weight: 400;
    }
  }

  &__icon {
    width: 14px;
    height: 14px;
    margin-right: 10px;

    path {
      fill: $blue;
    }
  }
}
