@import '../../assets/styles/colors';

.navbar {
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__view-title {
    color: $darkBlue;
    font-size: 28px;
    font-weight: 600;

    .button {
      margin-right: 20px;
    }
  }

  .button {
    height: 40px;
    padding: 0 20px;
  }
}
