@import 'assets/styles/colors';

.table {
  border-spacing: 0;
  width: 100%;
  color: $blackGray;

  &__head {
    background-color: $lightGray;
    color: black;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Montserat', sans-serif;
    position: sticky;
    width: 100%;
    left: 0;
    top: 0;

    th {
      line-height: 25px;
      padding: 0;
      &:first-child {
        padding-left: 10px;
      }
    }
  }

  &__row {
    > * {
      line-height: 32px;
      font-size: 14px;
      display: table-cell;
    }
    td:first-child {
      padding-left: 15px;
    }
    &:nth-child(2n) {
      background-color: $lightGray;
    }
    &:hover {
      background-color: $darkBlue;
      cursor: pointer;
      color: $white;
    }
  }

  .chip {
    font-size: 14px;
    max-height: 20px;
    line-height: 20px;
  }
}
