@import '~assets/styles/_colors.scss';

.button {
  color: $white;
  border-radius: 5px;
  height: 48px;
  font-size: 14px;
  font-weight: 600;
  padding: 0 15px;
  transition: background-color 150ms ease-in-out;
  font-family: $montserrat;

  span {
    font-family: $montserrat;
  }

  &.width-fill {
    width: 100%;
  }

  &.height-compact {
    height: 40px;
  }

  &.variant-contained {
    background-color: $blue;

    &.disabled {
      background-color: $button_disabledBg;
      border: 1px solid $input_border;
      cursor: not-allowed;
      color: $inactive;

      &:hover {
        background-color: $button_disabledBg;
      }
    }

    &:hover {
      background-color: $blueHover;
    }
  }

  &.variant-ghost {
    background-color: transparent;
    border: 1px solid $button_border;
    color: $lightBlue;
    font-weight: 400;

    &.disabled {
      background-color: $button_disabledBg;
      color: $inactive;
      cursor: not-allowed;
    }

    &:hover {
      background-color: $overlay;
    }
  }

  &.variant-text {
    background-color: transparent;
    color: $blue;
    font-weight: 400;

    &.disabled {
      color: $gray;
      cursor: not-allowed;
    }
  }

  .button__start-icon {
    margin-right: 10px;
  }
  .button__end-icon {
    margin-left: 10px;
  }
}
